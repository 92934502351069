import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { markdownToText } from './markdown-to-text';
import { createAsyncTask, formatDateTime, pageAlert } from './utils';
import { TicketDetail } from 'functions/tickets/[id]';
import { Ticket } from 'functions/tickets';

interface ListProps {
  ticketList?: Ticket[];
  refreshTicketList: () => Promise<void>;
}

export function renderTicketLabels(t:Ticket) {
  return <>
    <span className="badge text-bg-secondary">#{t.id}</span> { }
    <span className={`badge text-bg-${t.state == 'open' ? 'success' : 'danger'}`}>{t.state == 'open' ? '进行中' : '已关闭'}</span> { }
    <span className="badge text-bg-primary"><i className="bi bi-chat-dots-fill"></i> {t.comments}</span> { }
    <small>创建时间: {formatDateTime(t.created_at)}</small>
  </>;
}

export default function ({ticketList, refreshTicketList}:ListProps) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string|null>(null);
  const refresh = createAsyncTask(setLoading, setError, refreshTicketList);
  useEffect(() => {
    !ticketList && refresh();
  }, []);
  console.log(ticketList);
  return <div>
    <Link to="/new" className="btn btn-primary">创建工单</Link> { }
    <button disabled={isLoading} className="btn btn-primary" onClick={refresh}>{ isLoading ? '加载中...' : '刷新'}</button>
    <hr/>
    {pageAlert(error, setError)}
    <div className="list-group">
      {ticketList ? ticketList.map((t, idx) => <Link to={`/details/${t.id}`} key={idx} className="list-group-item list-group-item-action">
        <div className="d-flex w-full justify-content-between">
          <h5 className="mb-1 text-truncate">
            {t.title}
          </h5>
          {t.unread_comments > 0 && <span>
            <span className="badge rounded-pill bg-danger">
              {t.unread_comments}
            </span>
          </span>}
        </div>
        <p className="mb-1 text-truncate" dangerouslySetInnerHTML={{__html: markdownToText(t.body)}}/>
        <div className="d-flex w-full justify-content-between">
          <span>{renderTicketLabels(t)}</span>
          <small>更新时间: {formatDateTime(t.updated_at)}</small>
        </div>
      </Link>) : '暂无工单'}
    </div>
  </div>
}
