import { Editable, useEditor } from '@wysimark/react'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import type { TicketComment, TicketDetail } from 'functions/tickets/[id]';
import { createAsyncTask, formatDateTime, pageAlert } from './utils';
import { marked, Renderer } from 'marked';
import { renderTicketLabels } from './List';
import { Ticket } from 'functions/tickets';

const renderer = new Renderer();
renderer.image = (href, title, text) => {
  if (!href) {
    return text;
  }
  const params = href.split('#').at(-1);
  const width = params && params.match(/size=(\d+)x/);
  let out = `<img src="${href}" alt="${text}"`;
  if (width) {
    out += ` width="${width[1]}"`;
  } else {
    out += ' style="max-width: 500px"';
  }
  if (title) {
    out += ` title="${title}"`;
  }
  out += '>';
  return out;
};

function renderMarkdown(s:string) {
  return <div dangerouslySetInnerHTML={{__html: marked(s, { renderer }) as string}}></div>
}

interface TicketDetails {
  [id:number|string]:TicketDetail;
}

export interface DetailProps {
  ticketDetails:TicketDetails;
  setTicketDetail(id:number|string, ticketDetail:TicketDetail): void;
}

export default function (props:DetailProps) {
  const { id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [isLoading2, setLoading2] = useState(false);
  const [isLoading3, setLoading3] = useState(false);
  const [error, setError] = useState<string|null>(null);
  const [content, setContent] = useState('');
  const { ticket, comments } = props.ticketDetails[id!] || {};
  function getUnreadCommentBeginIndex(ticket:Ticket) {
    return ticket ?  ticket.comments - ticket.unread_comments : Infinity;
  }
  const [ unreadCommentBeginIndex, setUnreadCommentBeginIndex] = useState(getUnreadCommentBeginIndex(ticket));
  const navigate = useNavigate();
  if (!id) {
    navigate('/');
    return <></>;
  }
  const editor = useEditor({
    authToken: 'a',
    height: 300,
  });

  function setTicket(data:TicketDetail, flag = false) {
    props.setTicketDetail(id!, data);
    flag && setUnreadCommentBeginIndex(Infinity);
  }
  const refresh = createAsyncTask(setLoading, setError, async () => {
    const res = await fetch('/tickets/' + id);
    setTicket(await res.json());
  });
  const closeTicket = createAsyncTask(setLoading2, setError, async () => {
    const res = await fetch('/tickets/' + id, {
      method: 'DELETE',
    });
    setTicket({
      ticket: await res.json(),
      comments,
    }, true);
  });
  const addComment = createAsyncTask(setLoading3, setError, async () => {
    const res = await fetch('/tickets/' + id, {
      method: 'PATCH',
      body: content,
    });
    const comment = await res.json<TicketComment>();
    setTicket({
      ticket,
      comments: [...comments, comment],
    }, true);
  });
  useEffect(() => {
    if (!ticket || ticket.unread_comments > 0 || ticket.comments > comments.length) {
      refresh();
    }
  }, []);

  let ucbi = unreadCommentBeginIndex;
  while (comments && ucbi < comments.length && !comments[ucbi].is_staff) ucbi--;
  const mainContent = ticket && <>
    <div className="card mb-2">
      <div className="card-header">
        <div className="d-flex w-full justify-content-between">
          <span className="fw-bold">{ticket.title}</span>
          <span>{formatDateTime(ticket.updated_at)}</span>
        </div>
      </div>
      <div className="card-body">
        {renderMarkdown(ticket.body)}
      </div>
      <div className="card-footer">
        {renderTicketLabels(ticket)}
      </div>
    </div>

    {comments.map((c, idx) => <div key={idx} className={`card card-${c.is_staff ? 'blue' : 'green'} my-2`}>
      <div className="card-header">
        <div className="d-flex w-full justify-content-between">
          <span>{c.is_staff ? 'Pandownload Pro' : '你'}</span>
          <span>{formatDateTime(c.created_at)}</span>
        </div>
        { idx >= ucbi && c.is_staff && <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle"/>}
      </div>
      <div className="card-body">
      {renderMarkdown(c.body)}
      </div>
    </div>)}
  </>;

  return <div>
    <Link to="/" className="btn btn-primary">返回列表</Link> { }
    <button disabled={isLoading} className="btn btn-primary" onClick={refresh}>{ isLoading ? '加载中...' : '刷新'}</button> { }
    { ticket?.state === 'open' && <button
      disabled={isLoading2}
      className="btn btn-danger"
      onClick={() => {
        if (window.confirm('工单关闭后只能查看, 无法回复. 确定关闭?')) {
          closeTicket();
        }
      }}
    >
      { isLoading2 ? '关闭中...' : '关闭工单'}
    </button> }
    <hr/>
    {pageAlert(error, setError)}
    {mainContent}
    { ticket?.state === 'open' && <>
      <hr/>
      <div className="my-3 editor-fix">
        <p>
          <button
            disabled={isLoading3}
            className="btn btn-primary"
            onClick={() => {
              if (!content) {
                return alert('回复内容不能为空');
              }
              setContent('');
              addComment();
            }}
          >
            { isLoading3 ? '回复中...' : '回复'}
          </button> { }
          <button className="btn btn-primary" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>返回顶部</button>
        </p>
        <Editable editor={editor} value={content} onChange={setContent} />
      </div>
    </>}
  </div>;
}
