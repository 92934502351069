import AWS from 'aws-sdk';
import { ClientFile, HostedFileInfo } from "@portive/api-types";
import * as portiveClient from '@portive/client-x';
import { createClientFile } from './create-client-file';

// S3 Bucket Name
const S3_BUCKET = 'tickets';

// S3 Region
const REGION = 'auto';

const s3 = new AWS.S3({
  endpoint: 'https://2795af994904d2a590e88ad04584dc6a.r2.cloudflarestorage.com',
  accessKeyId: '695b3c5eaea12fe2b1c7fea85c04df62',
  secretAccessKey: '5de6f8d82d00ab02ada65678aca50c37b213896de9d7b57c8074ba931c4197e0',
  region: REGION,
  signatureVersion: 'v4',
});


export const uploadFile:typeof portiveClient.uploadFile = async (spec) => {
  const clientFile:ClientFile = await createClientFile(spec.file);
  const now = new Date();
  const extName = spec.file.name.split('.').at(-1);
  const filename = `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}/${now.valueOf()}_${Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString(36)}.${extName}`;
  const fileUrl = `https://static.pandownload.pro/${filename}`;
  const hostedFile: HostedFileInfo =
    clientFile.type === "image" ? {
        type: "image",
        url: fileUrl,
        width: clientFile.width,
        height: clientFile.height,
      }
    : { type: "generic", url: fileUrl };
  function createEvent<T extends string, U extends {}>(type:T, data?:U) {
    return {
      type,
      file: spec.file,
      clientFile,
      hostedFile,
      ...data as U,
    };
  }
  const beforeFetchEvent = createEvent('beforeFetch');
  spec.onBeforeFetch?.(beforeFetchEvent);
  spec.onUpdate?.(beforeFetchEvent);
  try {
    const beforeSendEvent = createEvent('beforeSend');
    spec.onBeforeSend?.(beforeSendEvent);
    spec.onUpdate?.(beforeSendEvent);
    await s3.putObject({
      Bucket: S3_BUCKET,
      Key: filename,
      Body: spec.file,
      ContentType: spec.file.type,
    }).on('httpUploadProgress', (ev) => {
      spec.onProgress?.(createEvent('progress', {
        sentBytes: ev.loaded,
        totalBytes: ev.total,
      }));
    }).promise();
  } catch (err) {
    const errorEvent = createEvent('error', { message: (err as any).message });
    spec.onError?.(errorEvent);
    spec.onFinish?.(errorEvent);
    spec.onUpdate?.(errorEvent);
    throw err;
  }

  const succEvent = createEvent('success');
  spec.onSuccess?.(succEvent);
  spec.onFinish?.(succEvent);
  spec.onUpdate?.(succEvent);
  return succEvent;
};

export class Client {

}

export * from '@portive/client-x';
