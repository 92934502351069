import { Editable, useEditor } from '@wysimark/react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import type { Ticket } from 'functions/tickets';
import { createAsyncTask, pageAlert } from './utils';

interface NewProps {
  appendTicket: (ticket:Ticket) => void;
}

export default function TicketForm({appendTicket}:NewProps) {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string|null>(null);
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');

  const editor = useEditor({
    authToken: 'a',
    height: 600,
  });

  const submit = createAsyncTask(setLoading, setError, async () => {
    const res = await fetch('/tickets', {
      method: 'POST',
      body: JSON.stringify({
        title,
        body: content,
      }),
    });
    if (res.status !== 200) {
      setError(res.statusText);
    } else {
      const ticket = await res.json<Ticket>();
      appendTicket(ticket);
      navigate('/');
    }
  });
  return <div>
    <Link to="/" className="btn btn-primary">返回列表</Link> { }
    <button
      disabled={isLoading}
      className="btn btn-success"
      onClick={() => {
        if (!title) {
          return alert('标题不能为空');
        }
        if (!content) {
          return alert('内容不能为空');
        }
        submit();
      }}
    >
      { isLoading ? '提交中...' : '提交'}
    </button>

    <hr/>
    {pageAlert(error, setError)}
    <div className="mb-3">
      <label className="form-label">标题</label>
      <input className="form-control" placeholder="标题" value={title} onChange={(ev) => {
        setTitle(ev.target.value)
      }}/>
    </div>
    <div className="mb-3 editor-fix">
      <label className="form-label">问题描述</label>
      <Editable editor={editor} value={content} onChange={setContent} />
    </div>
  </div>;
}
