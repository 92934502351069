import dayjs from 'dayjs';

export function formatDateTime(s:string) {
	return dayjs(s).format("YYYY-MM-DD HH:mm:ss");
}

export function pageAlert(error:string|null, setError:(s:string|null) => void) {
  return error && <div className="alert alert-danger alert-dismissible">
    {error}
    <button onClick={() => setError(null)} className="btn-close"></button>
  </div>;
}

export function createAsyncTask(setLoading:(b:boolean) => void, setError:(s:string|null) => void, task:() => any) {
  return async () => {
    setLoading(true);
    try {
      await task();
    } catch (err) {
      setError((err as any).message);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
}
