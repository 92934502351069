import { Link, Route, Routes } from 'react-router-dom';
import List from './List';
import New from './New';
import Detail, { DetailProps } from './Detail';
import { useState } from 'react';
import type { Ticket } from 'functions/tickets';
import { TicketDetail } from 'functions/tickets/[id]';


export default function () {
  const [tickets, setTickets] = useState<DetailProps['ticketDetails']>({});
  const [ticketIdList, setTicketIdList] = useState<number[]|undefined>();

  const ticketList = ticketIdList && ticketIdList.map((id) => tickets[id].ticket);
  async function refreshTicketList() {
    const res = await fetch('/tickets');
    const list = await res.json<Ticket[]>();
    const newTickets = { ...tickets };
    for (const ticket of list) {
      const { id } = ticket;
      newTickets[id] = { ticket, comments: tickets[id]?.comments || [] };
    }
    setTickets(newTickets);
    setTicketIdList(list.map((t) => t.id));
  }

  function appendTicket(ticket:Ticket) {
    const { id } = ticket;
    setTickets({
      ...tickets,
      [id]: { ticket, comments: tickets[id]?.comments || [] },
    });
    setTicketIdList([id, ...ticketIdList || []]);
  }
  function setTicketDetail(id:number|string, ticketDetail:TicketDetail) {
    setTickets({
      ...tickets,
      [id]: ticketDetail,
    });
  }

  return <div>
    <header className="p-3 text-bg-dark">
      <div className="container">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <Link to="/" className="d-flex align-items-center mb-3 mb-lg-0 me-lg-auto link-body-emphasis text-decoration-none">
          <img src="/logo.png" className="bi me-2" width="36"/>
            <span className="fs-4 text-white">Pandownload Pro 工单系统</span>
          </Link>
        </div>
      </div>
    </header>
    <div className="container mt-5">
      <Routes>
        <Route path="/" element={<List ticketList={ticketList} refreshTicketList={refreshTicketList}/>}/>
        <Route path="/new" element={<New appendTicket={appendTicket}/>} />
        <Route path="/details/:id" element={<Detail ticketDetails={tickets} setTicketDetail={setTicketDetail}/>} />
      </Routes>
    </div>
  </div>;
}
